import { ref,computed,getCurrentInstance,reactive,toRefs,onMounted,defineComponent} from 'vue';

export default defineComponent({
    name: "templateCard",
    title: "单据模板配置",
    modelType:'card',
    fullscreen: false,
    setup(){
        const {proxy} = getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj=reactive({
            formRef:null,
            billTypeTreeData: [],
            compParams: {
                modelPath: utils.Api.buildUrl("/template")
            },
            form: {}as any,
            rules:utils.UtilPub.commonFormValidRule([{modelId:'请选择模块'},{name:'请输入名称'}])
        })
        const beforeOpen=async(data:any,addOrLoad:string,engineInst:any)=>{
            dataObj.billTypeTreeData =JSON.parse(data.billTypeData);
            if(data.data.useFlow==0){
                dataObj.form.useFlow=false;
            }else{
                dataObj.form.useFlow=true;
            }
        }
        return{
            ...toRefs(dataObj),beforeOpen
        }
    },
    components: {}
});